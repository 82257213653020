@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

@import "../style/form.scss";
.uploader {
  width: 178px;
  height: 178px;
  border: 1px dashed #d9d9d9;

  img {
    width: 100%;
    height: 100%;
  }
}
.del-btn {
  position: absolute;
  right: 0;
  top: 0;
}
