.dialog {
  .flex-row {
    margin-bottom: 15px;
  }
}

.title {
  padding: 3px 8px;
  background: #eceafa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 70px;
  text-align: right;
}

.content-add {
  margin-left: 50%;
  transform: translateX(-50%);
}

.content-remark {
  right: -200px;
  color: #999999;
  font-size: 14px;
  position: absolute;
  bottom: 5px;
  width: 200px;
  font-size: 12px;
}

.slot-title {
  position: relative;

  .slot-button {
    position: absolute;
    right: 30px;
    top: -5px;
  }
}

.avator-block {
  width: 88px;
  height: 88px;
  background: #F8F7FF;
  border-radius: 44px;
  border: 1px solid #C5C0E0;
}

.computed-title {
  padding: 3px 8px;
  font-weight: 400;
  font-size: 15px;
  margin-right: 10px;
  width: 90px;
  text-align: center;
  border-left: 4px solid #C5C0E0;
}